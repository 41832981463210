import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";

// const root_url = "http://127.0.0.1:8000/apiv1/accounts/";
const root_url = "https://walse.pythonanywhere.com/apiv1/accounts/";

// const createAlert = (data) => {
//   return InfoStore.addNotification({
//     title: data.title,
//     message: data.message,
//     type: data.type,
//     insert: "top",
//     container: "top-right",
//     animationIn: ["animate__animated animate__fadeIn"],
//     animationOut: ["animate__animated animate__fadeOut"],
//     dismiss: {
//       duration: 5000,
//     },
//   });
// };

const initialState = {
  isLoading: null,
  errors: "",
  authenticated: null,
  userDetails: null,
  userToken: "",
  userMessage: "",
  userMessageType: "",
};

// async fucntion
export const login = createAsyncThunk(
  "auth/login",
  async (userData, { rejectWithValue }) => {
    try {
      let res = await axios.post(`${root_url}signin/`, userData);
      if (res.status === 200) {
        const token = jwtDecode(res.data.access);
        if (token.role) {
          localStorage.setItem("adminToken", res.data.access);

          return {
            token: res.data.access,
            details: {
              first_name: token.first_name,
              email: token.email,
              userId: token.id,
            },
          };
        }

        return rejectWithValue({ message: "Access Denied", type: "loginErr" });
      }

      return rejectWithValue({ message: "login failed", type: "loginErr" });
    } catch (error) {
      return rejectWithValue({
        type: "loginErr",
        message:
          error.response?.status === 401
            ? "Wrong Email and Password"
            : error.response?.email?.length > 0
            ? error.response.data.email[0]
            : "Network Error",
      });
    }
  }
);
export const logout = createAsyncThunk("auth/logout", async () => {
  if (localStorage.getItem("adminToken")) localStorage.removeItem("adminToken");
  return true;
});

export const checkIfUserLogin = createAsyncThunk(
  "auth/checkIfUserLogin",
  async (userdata, { rejectWithValue }) => {
    const adminToken = localStorage.getItem("adminToken")
      ? localStorage.getItem("adminToken")
      : null;
    if (adminToken !== null) {
      const tokenClaims = jwtDecode(adminToken);
      return {
        token: adminToken,
        details: {
          first_name: tokenClaims.first_name,
          email: tokenClaims.email,
          userId: tokenClaims.id,
        },
      };
    }
    return rejectWithValue({ data: null });
  }
);
//  REGISTRATION
export const register = createAsyncThunk(
  "Auth/register",
  async (userData, { extra, rejectWithValue }) => {
    let res;
    try {
      res = await axios.post(`${root_url}create/`, userData);
      if (res.status !== 201) {
        return rejectWithValue({ message: "something went wrong" });
      }
      return res.data;
    } catch (error) {
      return rejectWithValue({
        message:
          error.response && error.response.data.email
            ? error.response.data.email[0]
            : error.message,
      });
    }
  }
);

export const recoverAccount = createAsyncThunk(
  "auth/recoverAccount",
  async (userData, { extra, rejectWithValue }) => {
    try {
      let res = await axios.post(`${root_url}recovery/`, userData);
      if (res.data.message) {
        return rejectWithValue({
          message: " Account not Found",
        });
      }
      return { message: "An email has been sent to you" };
    } catch (error) {
      rejectWithValue({
        message: "Something went Wrong! Please try again",
      });
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (userData, { extra, rejectWithValue }) => {
    if (userData.route.email === "" || userData.route.time === "") return;
    if (
      userData.password1 === "" ||
      userData.password2 === "" ||
      userData.password1 !== userData.password2
    ) {
      return;
    }
    try {
      let res = await axios.post(
        `${root_url}/recovery/${userData.route.email}/${userData.route.time}/`,
        { password: userData.password1 }
      );
      if (res.data.status) return { status: "success" };
      return rejectWithValue({ status: res.data.message });
    } catch (error) {
      if (error.response?.data.message)
        return rejectWithValue({ status: error.response.data.message });
      return rejectWithValue({ status: "failed" });
    }
  }
);

// userAuth Slice/reducers/extraReducers
//
export const userSlice = createSlice({
  initialState,
  name: "auth",
  reducers: {
    clearError: (state, action) => {
      state.userMessage = "";
      state.userMessageType = "";
      state.errors = "";
    },
    showAlert: (state, action) => {
      // createAlert(action.payload);
    },
  },
  extraReducers: (builder) => {
    //  checking for saved usertoken

    builder.addCase(checkIfUserLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(checkIfUserLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userDetails = action.payload.details
      state.userToken = action.payload.token;
      state.authenticated = true;
    });
    builder.addCase(checkIfUserLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.authenticated = false;
      state.userToken = initialState.userToken;

      state.errors = action.error.message;
    });
    // Login
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.errors = "";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.userToken = action.payload.token;
      state.authenticated = true;
      state.userDetails=action.payload.details
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLoading = false;
      state.authenticated = false;
      state.userToken = initialState.userToken;
      state.errors = action.payload
        ? action.payload.message
        : action.error.message;
    });

    // ******************
    //
    // ******************

    // logout a user
    builder.addCase(logout.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      state.isLoading = false;
      state.authenticated = false;
      state.userToken = initialState.userToken;
    });
    // TODO make sure that logout faliure reject with error message
    builder.addCase(logout.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    // ###########
    // ###########
    builder.addCase(register.pending, (state) => {
      state.errors = "";
      state.isLoading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.isLoading = false;
      state.new_reg = true;
      toast.success(
        `Welcome $${action.payload.last_name}!  your account was created successfull`,
        { position: "top-right", delay: 300 }
      );
    });
    builder.addCase(register.rejected, (state, action) => {
      state.isLoading = false;
      state.errors = action.payload
        ? action.payload.message
        : "Something went Wrong";
    });
    // recoverAccount
    builder.addCase(recoverAccount.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(recoverAccount.fulfilled, (state, action) => {
      state.isLoading = false;
      // createAlert({
      //   message:
      //     "An e-mail has been sent to you. Please follow the instr=uction to reaet your password.",
      //   type: "info",
      //   title: "password Recovery",
      // });
    });
    builder.addCase(recoverAccount.rejected, (state, action) => {
      state.isLoading = false;

      state.userMessage = state.userMessageType = "error";
    });

    // resetPassword
    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.userMessage = action.payload.message;
      state.userMessageType = action.payload.type;
      state.isLoading = false;
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.userMessage = action.error.message;
      state.userMessageType = "error";
      state.isLoading = false;
    });
  },
});

export const { clearError, showAlert } = userSlice.actions;

export default userSlice.reducer;
