import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllBracelet,
  fetchAllChains,
  fetchAllEarrings,
  fetchAllProduct,
  fetchAllRings,
  fetchAllWatches,
} from "../../state/features/product/productsSlice";
import TableThree from "../../components/Table";
import { useLocation, useParams } from "react-router-dom";

function Products() {
  const products = useSelector((state) => state.product.products);
  const location = useLocation();
  const params = useParams();

  let { search } = location;
  const offsetRef=useRef(null)

  const dispatch = useDispatch();
  useEffect(() => {
    if (params.category === "watches") {
      dispatch(fetchAllWatches());
    }
    if (params.category === "rings") {
      if (search) {
        dispatch(fetchAllRings({ page: 1, search: "engagement-rings" }));
      } else {
        dispatch(fetchAllRings());
      }
    }
    if (params.category === "earrings") {
      dispatch(fetchAllEarrings());
    }
    if (params.category === "bracelets") {
      dispatch(fetchAllBracelet());
    }
    if (params.category === "chains") {
      dispatch(fetchAllChains());
    }
    if (params.category === "all-products") {
      dispatch(fetchAllProduct(1));
    }
    if (params.category === undefined) {
      dispatch(fetchAllProduct(1));
    }
  }, [dispatch, params]);

  useEffect(() => {
    offsetRef.current.scrollIntoView()
  }, [products,location]);

 
  return (
    <div ref={offsetRef}>
      <TableThree products={products} />
    </div>
  );
}

export default Products;
