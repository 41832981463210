import React from "react";
import { closeDelWarn } from "../../state/features/ui_utils/ui_utilsSlice";
import { useDispatch, useSelector } from "react-redux";
import { editProduct } from "../../state/features/product/productsSlice";
import { editUser } from "../../state/features/users/userSlice";
import { useLocation } from "react-router-dom";

function DeleteModal() {
  const item_id = useSelector((state) => state.ui.toDeleteItemId);
const location=useLocation()
  const dispatch = useDispatch();
  return (
    <div
      className="modal-container h-[100vh] w-[100vw] mx-auto top-0 left-0 z-999999 absolute  bg-[#100a15d0] bg-blend-multiply "
      onClick={(e) => {
        alert();
        e.stopPropagation();
        // if (e.target.className === "modal-container") closeModal();
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modal lg:w-6/12 w-10/12  my-16 mx-auto  rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark overflow-auto"
      >
        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
          <div className="w-full flex justify-end">
            <strong
              className="text-xl align-center cursor-pointer "
              onClick={(e) => {
                dispatch(closeDelWarn());
              }}
            >
              &times;
            </strong>
          </div>
          <div className="message">
            <p className="text-center">
              Do you want to permently delete this {item_id.type==="user"?"User":"Product"}?
            </p>
            <div className="btns flex gap-x-5 mt-10 justify-center">
              <button
                onClick={() => dispatch(closeDelWarn())}
                className="inline-flex text-red-500 bg-[#f6d5d5d6] border-0 py-2 px-6 rounded text-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  dispatch(
                    item_id.type==="user"?editUser({action:"delete",from:location,item:item_id.id}):editProduct({ item: { id: item_id.id }, action: "delete" })
                  );
                  dispatch(closeDelWarn())
                }}
                className="inline-flex text-white bg-[#282828] border-0 py-2 px-6 rounded text-lg"
              >
                Yes Please
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteModal;
