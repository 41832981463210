import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, Outlet, useLocation } from "react-router-dom";
import { checkIfUserLogin, clearError } from "../../state/features/auth/authSlice";


const Authentication = () => {
  const auth = useSelector((state) => state.auth.authenticated);
  const loacation=useLocation()
  
  const dispatch=useDispatch()
useEffect(()=>{
  dispatch(clearError())
},[loacation.pathname,dispatch])



  return auth ? (
    <Navigate to={"/"} />
  ) : (
    <div className="rounded-sm   bg-white ">

      <header className="sticky top-0 z-999 flex w-full bg-white drop-shadow-1 dark:bg-boxdark dark:drop-shadow-none">
        <div className="flex flex-grow items-center justify-center lg:justify-start px-4 py-4 shadow-2 md:px-6 2xl:px-11">
          <div className="flex items-center  gap-2 sm:gap-4  ">
            {/* <!-- Hamburger Toggle BTN --> */}
            <Link className={`block flex-shrink-0`} to="/">
              <p className="text-black  font-satoshi font-bold text-2xl">
                El Classic Jewelry
              </p>
            </Link>
          </div>
        </div>
      </header>
      <div className="flex justify-center flex-wrap items-center lg:pt-0 pt-20">
        <Outlet />
      </div>
    </div>
  );
};

export default Authentication;
