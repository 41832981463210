import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../components/Header";
import Sidebar from "../components/SideBar";
import { Modal } from "../components/modals/ProductEditModal";
import { useSelector } from "react-redux";
import DeleteModal from "../components/modals/DeleteModal";
import UserEditModal from "../components/modals/UserEditModal";

function Frame() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const edit_modal = useSelector((state) => state.ui.openProductEditModal);
  const openUserEditModal=useSelector(state=>state.ui.openUserEditModal)
  const openDeleteModal = useSelector(
    (state) => state.ui.openProductDeleteWarningModal
  );

  const onSubmit = (data) => {
    // dispatch data to edit_product reducer
  };

  return (
    <div className="dark:bg-boxdark-2 dark:text-bodydark">
      {/* <!-- ===== Page Wrapper Start ===== --> */}
      <div className="flex h-screen overflow-hidden">
        <div className={!edit_modal && "hidden"}>
          <Modal onSubmit={onSubmit} />
        </div>
        <div className={!openDeleteModal && "hidden"}>
          <DeleteModal />
        </div>
        <div className={!openUserEditModal && "hidden"}>
       < UserEditModal/>        </div>

        {/* <!-- ===== Sidebar Start ===== --> */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        {/* <!-- ===== Sidebar End ===== --> */}

        {/* <!-- ===== Content Area Start ===== --> */}
        <div className="relative flex flex-1 flex-col overflow-y-auto overflow-x-hidden">
          {/* <!-- ===== Header Start ===== --> */}
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* <!-- ===== Header End ===== --> */}

          {/* <!-- ===== Main Content Start ===== --> */}
          <main>
            <div className="mx-auto max-w-screen-2xl p-4 md:p-6 2xl:p-10">
              <Outlet />
            </div>
          </main>
          {/* <!-- ===== Main Content End ===== --> */}
        </div>
        {/* <!-- ===== Content Area End ===== --> */}
      </div>
      {/* <!-- ===== Page Wrapper End ===== --> */}
    </div>
  );
}

export default Frame;
