import { createBrowserRouter } from "react-router-dom";
import AuthRequired from "./pages/auth/authRequired";
import Frame from "./pages/Frame";
import Login from "./pages/auth/Login";
import Authentication from "./pages/auth/AuthenticationFrame";
import Registration from "./pages/auth/Registraion";
import Profile from "./pages/Profile";
import UsersPage from "./pages/users";
import Products from "./pages/product/products";
import ErrorPage from "./pages/auth/404Error";

export const router = createBrowserRouter([
  {
    path: "/users/profile/",
    element: <AuthRequired />,
    children: [
      { path: "", element: <Profile />, index: true },
      { path: "edit", element: <Profile />, index: true },
    ],
  },

 
  // { path: "/accounts/recovery", element: <Recovery/> },
  // { path: "/accounts/recovery/:email/:time", element: <ToNewPassword /> },
  // { path: "/accounts/recovery/reset-password", element: <NewPassword /> },
  {
    path: "/auth/",
    element: <Authentication />,
  
    children: [
      { path: "signin", element: <Login />,  index: true },
      { path: "signup", element: <Registration />,  },
    ],
  },
  {
    path: "",
    element: <AuthRequired />,
    children: [
      {
        path: "",
        element: <Frame />,
        children: [
          { path: "", element: <Products />, index: true },
          { path: "/products/:category" ,element: <Products />, },
          { path: "/customers", element: <UsersPage />,},
          { path: "/admin-users", element: <UsersPage />, },
          { path: "/users/:id", element: <Profile />, },          

        
        ],
      },
    ],
  },
  { path: "*", element: <ErrorPage /> },

]);
