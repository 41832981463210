import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import jwt from "jsonwebtoken"
import axios from "axios";
import { close } from "../ui_utils/ui_utilsSlice";
import { toast } from "react-toastify";

// const base_url = "http://127.0.0.1:8000/apiv1/shop/collections/";
const base_url = "https://walse.pythonanywhere.com/apiv1/shop/collections/";

const initialState = {
  products: {
    count: 0,
    results: [],
    previous: "",
    next: "",
  },
  product: {},
  updated:null,
  isUpdating:false,
  isLoading: false,
};

export const paginate = createAsyncThunk(
  "product/paginate",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.get(`${payload}`);
      if (res.status !== 200) {
        return rejectWithValue({ message: "some went Wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);
export const fetchAllProduct = createAsyncThunk(
  "product/fetchAllProduct",
  async (payload, { rejectWithValue }) => {
    try {
      let res = await axios.get(
        `${base_url}jewellery?filter=rings&page=${payload}`
      );
      if (res.status !== 200) {
        return rejectWithValue({ message: "some went Wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);
export const fetchAllEarrings = createAsyncThunk(
  "product/fetchAllEarrings",
  async (payload = 1, { rejectWithValue }) => {
    try {
      let res = await axios.get(
        `${base_url}jewellery?filter=earrings&page=${payload}`
      );
      if (res.status !== 200) {
        return rejectWithValue({ message: "some went Wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);
export const fetchAllWatches = createAsyncThunk(
  "product/fetchAllWatches",
  async (payload = 1, { rejectWithValue }) => {
    try {
      let res = await axios.get(`${base_url}watches?filter=new-in`);
      if (res.status !== 200) {
        return rejectWithValue({ message: "some went Wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);
export const fetchAllBracelet = createAsyncThunk(
  "product/fetchAllBracelet",
  async (payload = 1, { rejectWithValue }) => {
    try {
      let res = await axios.get(
        `${base_url}jewellery?filter=bracelets&page=${payload}`
      );
      if (res.status !== 200) {
        return rejectWithValue({ message: "some went Wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);
export const fetchAllChains = createAsyncThunk(
  "product/fetchAllChains",
  async (payload = 1, { rejectWithValue }) => {
    try {
      let res = await axios.get(
        `${base_url}jewellery?filter=chains&page=${payload}`
      );
      if (res.status !== 200) {
        return rejectWithValue({ message: "some went Wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);
export const fetchAllRings = createAsyncThunk(
  "product/fetchAllRings",
  async (payload = { page: 1, search: "rings" }, { rejectWithValue }) => {
    let res;
    try {
      if (payload.search === "engagement-rings") {
        res = await axios.get(
          `${base_url}${payload.search}?page=${payload.page}`
        );
      } else {
        res = await axios.get(
          `${base_url}jewellery?filter=${payload.search}&page=${payload.page}`
        );
      }
      if (res.status !== 200) {
        return rejectWithValue({ message: "some went Wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);
export const editProduct = createAsyncThunk(
  "product/editProduct",
  async (payload, { getState, rejectWithValue,dispatch }) => {
    // const userToken=getState().auth.userToken
    // axios.defaults.headers={Authorization:`Bearer ${token}`}
    let res;
    const token = getState().auth.userToken;
    let { action, from,...item } = payload;

    let data = { ...item.item };
    data.number_in_stock = data.avail_stock;
    try {
      if (action === "delete") {
        res = await axios.delete(
          `${base_url}admin/products/${payload.item.id}/`,

          {
            headers: { Authorization: `Bearer ${token}` },
            data: payload.item,
          }
        );
      } else {
        res = await axios.post(
          `${base_url}admin/products/${payload.item.id}/`,
          data,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if(res.status===200){

          dispatch(close())
        }
        rejectWithValue({message:"Something ho"})
      }
    } catch (err) {
      if (err.response?.status === 401) {
        return rejectWithValue({
          message: "You must be Login to perform this Operation",
        });
      }
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);

export const productSlice = createSlice({
  initialState: initialState,
  name: "product",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllProduct.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllProduct.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(fetchAllProduct.rejected, (state, action) => {
      state.isLoading = false;
      state.products = initialState.products;
    });
    // WATCHES
    builder.addCase(fetchAllWatches.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllWatches.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(fetchAllWatches.rejected, (state, action) => {
      state.isLoading = false;
      state.products = initialState.products;
    });
    // RINGS
    builder.addCase(fetchAllRings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllRings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(fetchAllRings.rejected, (state, action) => {
      state.isLoading = false;
      state.products = initialState.products;
    });
    // EARRINGS
    builder.addCase(fetchAllEarrings.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllEarrings.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(fetchAllEarrings.rejected, (state, action) => {
      state.isLoading = false;
      state.products = initialState.products;
    });
    // Bracelet
    builder.addCase(fetchAllBracelet.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllBracelet.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(fetchAllBracelet.rejected, (state, action) => {
      state.isLoading = false;
      state.products = initialState.products;
    });
    // CHAINS
    builder.addCase(fetchAllChains.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllChains.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(fetchAllChains.rejected, (state, action) => {
      state.isLoading = false;
      state.products = initialState.products;
    });
    // PAGINATE
    builder.addCase(paginate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(paginate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.products = action.payload;
    });
    builder.addCase(paginate.rejected, (state, action) => {
      state.isLoading = false;
      state.products = initialState.products;
    });
    // 
    builder.addCase(editProduct.pending, (state, action) => {
      state.isUpdating = false;
      state.updated=null
      
      
    });
    builder.addCase(editProduct.rejected, (state, action) => {
      state.isUpdating = false;
      state.updated=false
      state.updateMessage=action.payload.message
    });
    builder.addCase(editProduct.fulfilled, (state, action) => {
      state.isUpdating = false;
      state.updated=true
      toast.success("Product updated")
    });
  },
});

// export const{}=productSlice.actions

export default productSlice.reducer;
