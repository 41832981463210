import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUser } from "../../state/features/users/userSlice";
import { close } from "../../state/features/ui_utils/ui_utilsSlice";
import Select from "react-select";
import Avatar from "../Avatar";
import { useLocation } from "react-router-dom";

const UserEditModal = () => {
  const defaultValue = useSelector((state) => state.ui.user_data);
  const isUpdating = useSelector((state) => state.user.isUpdating);
  const location=useLocation()

  const dispatch = useDispatch();

  const [formState, setFormState] = useState(defaultValue);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setFormState(defaultValue);
    setErrors([]);
  }, [defaultValue]);

  const validateForm = () => {
    let errorFields = {};
    setErrors({});
    Object.entries(formState).forEach((field) => {
      if (field[0] !== "id" || field[0] !== "sold_out") {
        field[1] === ""
          ? (errorFields = { ...errorFields, [field[0]]: "This is required" })
          : (errorFields = { ...errorFields });
      }
    });

    if (Object.keys(errorFields).length < 1) {
      return true;
    }
    setErrors({ ...errorFields });
    return false;
  };

  const handleChange = (e, field) => {
    setFormState({ ...formState, [field]: e });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    dispatch(editUser({ item: formState, action: "edit",from:location }));
  };

  return (
    <div
      className="modal-container h-[100vh] w-[100vw] mx-auto top-0 left-0 z-999999 absolute  bg-[#100a15e2] bg-blend-multiply "
      onClick={(e) => {
        alert();
        e.stopPropagation();
        // if (e.target.className === "modal-container") closeModal();
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modal w-11/12 h-5/6  my-16 mx-auto  rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark overflow-auto"
      >
        <div className="border-b relative border-strok justify-center px-5 lg:px-10 items-center flex flex-col h-full  dark:border-strokedark">
          <div className="absolute lg:top-10 top-5 right-5 w-10 h-10 bg-red-100 text-center rounded-lg lg:right-10">
            <strong
              className="text-4xl  rounded-full text-red-400    flex justify-center items-center cursor-pointer "
              onClick={(e) => {
                dispatch(close());
              }}
            >
              &times;
            </strong>
          </div>
          <form className="justify-center w-full  items-center border-slate-300 border-2 px-5 rounded-lg  py-5   ">
            <div className="id mb-5">
              <Avatar email={formState.email} />
              <div className="font-bold tracking-wide uppercase text-clip overflow-x-scroll pt-1 pb-2">
                {formState.email}
              </div>
            </div>

            <div className="lg:grid grid-cols-12 lg:gap-5 gap-3 gap-y-7 lg:gap-y-10 lg:space-y-0  space-y-5  justify-normal">
              <div className="form-group w-full col-span-3 ">
                <input
                  id="first_name"
                  type="text"
                  className="w-full  bg-gray py-3 pl-3 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="first_name"
                  onChange={(e) => handleChange(e.target.value, "first_name")}
                  value={formState.first_name}
                />
                {errors.first_name && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.first_name}
                  </p>
                )}
              </div>
              <div className="form-group w-full col-span-3 ">
                <input
                  id="last_name"
                  type="text"
                  className="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="last_name"
                  onChange={(e) => handleChange(e.target.value, "last_name")}
                  value={formState.last_name}
                />
                {errors.last_name && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.last_name}
                  </p>
                )}
              </div>
              {/* IS_ACTIVE */}
              <div className="py-3 w-full  ">
                <label htmlFor="active" className="pb-2">
                  <input
                    name="active"
                    checked={formState.is_active}
                    onChange={(e) =>
                      handleChange(e.target.checked, "is_active")
                    }
                    type="checkbox"
                    id="active"
                    className="mr-2"
                  />
                  Active
                </label>
                {errors.is_active && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.is_active}
                  </p>
                )}
              </div>
              {/* IS_ACTIVE */}
              <div className="py-3 w-full  ">
                <label htmlFor="admin" className="pb-2">
                  <input
                    name="admin"
                    checked={formState.is_superuser}
                    onChange={(e) =>
                      handleChange(e.target.checked, "is_superuser")
                    }
                    type="checkbox"
                    id="admin"
                    className="mr-2"
                  />
                  Admin
                </label>
                {errors.is_superuser && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.is_superuser}
                  </p>
                )}
              </div>
            </div>

            <div className="flex justify-end items-end gap-x-5 mt-10">
              <button
              disabled={isUpdating}
                className="btn  flex justify-center rounded bg-neutral-500 py-2 px-6 font-medium text-gray hover:shadow-1"
                type="button"
                onClick={() => dispatch(close())}
              >
                Cancel
              </button>{" "}
              <button
                disabled={isUpdating}
                className="btn  flex justify-center rounded bg-[#282828] hover:bg-slate-800 py-2 px-6 font-medium text-gray hover:shadow-1"
                type="button"
                onClick={handleSubmit}
              >
                {isUpdating ? "Loading..." : " Save "}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserEditModal;
