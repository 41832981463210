import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openProductEditModal: false,
  openProductDeleteWarningModal: false,
  openUserEditModal:false,
  toDeleteItemId: {},

  user_data: {
    first_name: "",
    last_name: "",
    id: "",
    is_active: true,
    is_superuser: true,
  },
  product_data: {
    name: "",
    price: 0,
    avail_stock: 0,
    discount_percent: 0,
    sold_out: false,
    discount_avail: true,
    new_arrival: true,
    feautured: true,
    img: "",
  },
};

export const ui_utilsSlice = createSlice({
  initialState: initialState,
  name: "ui_utils",
  reducers: {
    open: (state, action) => {
      if (action.payload.userFunc === undefined) {
        state.openProductEditModal = true;
        state.product_data = {
          name: action.payload.name,
          price: action.payload.price,
          avail_stock: action.payload.number_in_stock,
          discount_percent: action.payload.discount_percent,
          sold_out: action.payload.sold_out,
          discount_avail: action.payload.discount_avail,
          new_arrival: action.payload.new_arrival,
          feautured: action.payload.feautured,
          img: action.payload.img1,
          id: action.payload.id,
        };
      } else {
        state.openUserEditModal = true;
        state.user_data = {
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          id: action.payload.id,
          email:action.payload.email,
          is_active: action.payload.is_active,
          is_superuser: action.payload.is_superuser,
        };
      }
    },
    close: (state, action) => {
      state.openProductEditModal = false;
      state.openUserEditModal=false
      // state.product_data = initialState.product_data;
    },
    openDelWarn: (state, action) => {
      
      state.openProductDeleteWarningModal = true;
      state.toDeleteItemId = action.payload;
    },
   
    closeDelWarn: (state, action) => {
      state.openProductDeleteWarningModal = false;
      state.toDeleteItemId = {};

    },
  },
});

export const { open, close, openDelWarn, closeDelWarn } = ui_utilsSlice.actions;

export default ui_utilsSlice.reducer;
