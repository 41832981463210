import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { close } from "../ui_utils/ui_utilsSlice";
// const root_url = "http://127.0.0.1:8000/apiv1/accounts/";
const root_url = "https://walse.pythonanywhere.com/apiv1/accounts/";

const initialState = {
  users: {
    next: null,
    prev: null,
    results: [],
  },
  user: {},
  isLoading: false,
  isUpdating: false,
  updated:null,
  userUpdateStatus: {}
};

export const fetchAllUsers = createAsyncThunk(
  "user/fetchAllUsers",
  async (payload, { getState, rejectWithValue }) => {
    const token = getState().auth.userToken;

    try {
      let res = await axios.get(`${root_url}list-create`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status !== 200) {
        return rejectWithValue({ message: "Something went wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({ message: "Something went wrong" });
    }
  }
);
export const fetchAllAdmin = createAsyncThunk(
  "user/fetchAllAdmin",
  async (payload, { getState, rejectWithValue }) => {
    const token = getState().auth.userToken;

    try {
      let res = await axios.get(`${root_url}list-create?filter=admins`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status !== 200) {
        return rejectWithValue({ message: "Something went wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({ message: "Something went wrong" });
    }
  }
);

export const paginate = createAsyncThunk(
  "user/paginate",
  async (payload, { rejectWithValue, getState }) => {
    const token = getState().auth.userToken;
    try {
      let res = await axios.get(`${payload}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.status !== 200) {
        return rejectWithValue({ message: "some went Wrong" });
      }
      return res.data;
    } catch (err) {
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);

export const editUser = createAsyncThunk(
  "user/editUser",
  async (payload, { getState, rejectWithValue, dispatch }) => {
    const userToken = getState().auth.userToken;
    if (!jwtDecode(userToken).role) {
      return rejectWithValue({ message: "Access Denied" });
    }
    axios.defaults.headers = { Authorization: `Bearer ${userToken}` };
    let res;

    let { action, from, ...item } = payload;

    let data = item;
    try {
      if (action === "delete") {
      
        res = await axios.get(`${root_url}admin/users/${item.item}/`);
        if (res.status === 200) { return { message: "Item Deleted Successfuly" } }
      } else {
        res = await axios.post(`${root_url}list-create`, data.item,);
        if (res.status === 200) {
          from === "/admin-users" && dispatch(fetchAllAdmin())
          from === "/customers" && dispatch(fetchAllUsers())
          dispatch(close())
          return res.data
        }
        return rejectWithValue({})
      }
    } catch (err) {


      if (err.response?.status === 401) {
        return rejectWithValue({
          message: "Please Contact  Admin for futher Help",
        });
      }
      return rejectWithValue({
        message: "something went Wrong please try again",
      });
    }
  }
);

export const userSlice = createSlice({
  initialState: initialState,
  name: "user",
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllUsers.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    });
    builder.addCase(fetchAllUsers.rejected, (state) => {
      state.isLoading = false;
      state.user = { ...initialState };
    });
    //  FETCH ALL ADMIN USERS
    builder.addCase(fetchAllAdmin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchAllAdmin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    });
    builder.addCase(fetchAllAdmin.rejected, (state, action) => {
      state.isLoading = false;
      state.user = { ...initialState };
    });
    // PAGINATE USERS
    builder.addCase(paginate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(paginate.fulfilled, (state, action) => {
      state.isLoading = false;
      state.users = action.payload;
    });
    builder.addCase(paginate.rejected, (state, action) => {
      state.isLoading = false;
      state.user = { ...initialState };
    });
    // 
    // 
    // EDITH USERS BY ADMIN
    builder.addCase(editUser.pending, (state,) => {
      state.isUpdating = true;
      state.updated=null
    });
    builder.addCase(editUser.fulfilled, (state, action) => {
      state.isUpdating = false;
      state.updated=true
       toast.success("Account updated successful");
       
      });
      builder.addCase(editUser.rejected, (state, action) => {
        state.isUpdating = false;
        state.updated=false
        toast.error("Account updated failed");

      // state.user = { ...initialState };
    });
  },
});

export default userSlice.reducer;
