import React, { useEffect } from "react";
import md5 from "md5";

function Avatar({ email }) {
  const imgUrl = `https://gravatar.com/avatar/HASH ${email}`;

  return (
    <div>
      <img
        className="w-10 h-10 p-1 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
        src={imgUrl}
        alt="Bordered "
      />
    </div>
  );
}

export default Avatar;
