import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  fetchAllAdmin,
  fetchAllUsers,
} from "../state/features/users/userSlice";
import UserTable from "../components/UserTable";

const UsersPage = () => {
  const location = useLocation();
  const update = useSelector((state) => state.user?.updated);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);

  const offsetRef = useRef(null);

  useEffect(() => {
    offsetRef.current.scrollIntoView();
  }, [users]);

  useEffect(() => {
    if (location.pathname.includes("admin-users")) {
      dispatch(fetchAllAdmin(1));
    } else {
      dispatch(fetchAllUsers(1));
    }
  }, [dispatch, location, update]);

  return (
    <div ref={offsetRef}>
      <UserTable users={users} />
    </div>
  );
};
export default UsersPage;
