import React from "react";
import { Modal } from "../components/modals/ProductEditModal";

function Profile() {
  return (
    <div>
   user
    </div>
  );
}

export default Profile;
