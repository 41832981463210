import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { close } from "../../state/features/ui_utils/ui_utilsSlice";
import { editProduct } from "../../state/features/product/productsSlice";
import Select from "react-select";
import { useLocation } from "react-router-dom";

export const Modal = () => {
  const defaultValue = useSelector((state) => state.ui.product_data);
  const updated = useSelector((state) => state.product.updated);
  const errMessage = useSelector((state) => state.product.updatedMessage);
  const isLoading=useSelector(state=>state.product.isUpdating)


  const dispatch = useDispatch();
  const location = useLocation();

  const [formState, setFormState] = useState(defaultValue);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setFormState(defaultValue);
    setErrors([]);
  }, [defaultValue]);

  const validateForm = () => {
    let errorFields = {};
    setErrors({});
    Object.entries(formState).forEach((field) => {
      if (field[0] !== "id" || field[0] !== "sold_out") {
        field[1] === ""
          ? (errorFields = { ...errorFields, [field[0]]: "This is required" })
          : (errorFields = { ...errorFields });
        if (field[0] === "price") {
          parseInt(field[1]) <= 0
            ? (errorFields = {
                ...errorFields,
                [field[0]]: "Price can not be less than $1",
              })
            : (errorFields = { ...errorFields });
        }

        if (field[0] === "avail_stock") {
          parseInt(field[1]) < 0
            ? (errorFields = {
                ...errorFields,
                [field[0]]: "Stock can not be less than 0",
              })
            : (errorFields = { ...errorFields });
        }

        if (field[0] === "discount_percent") {
          parseInt(field[1]) < 0
            ? (errorFields = {
                ...errorFields,
                [field[0]]: "Invalid: Discount can not be less than 0",
              })
            : (errorFields = { ...errorFields });
        }
      }
    });

    if (Object.keys(errorFields).length < 1) {
      return true;
    }
    setErrors({ ...errorFields });
    return false;
  };

  const handleChange = (e, field) => {
    setFormState({ ...formState, [field]: e });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    dispatch(editProduct({ item: formState, action: "edit", from: location }));
  };

  const myOption = [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ];

  return (
    <div
      className="modal-container h-[100vh] w-[100vw] mx-auto top-0 left-0 z-999999 absolute  bg-[#100a15e2] bg-blend-multiply "
      onClick={(e) => {
        alert();
        e.stopPropagation();
        // if (e.target.className === "modal-container") closeModal();
      }}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="modal w-11/12 h-5/6  my-16 mx-auto  rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark overflow-auto"
      >
        <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
          <div className="w-full flex justify-end">
            <strong
              className="text-xl align-center cursor-pointer "
              onClick={(e) => {
                dispatch(close());
              }}
            >
              &times;
            </strong>
          </div>
          <form>
            {updated === false && (
              <div className=" text-center bg-red-200 py-4 lg:mx-10 mx-0  my-5 mb-10 errorMessage">
                {errMessage}
              </div>
            )}
            <div className="grid grid-cols-12 lg:gap-5 gap-3 gap-y-7 lg:gap-y-10   justify-normal">
              <img
                className=" lg:col-span-3 lg:row-span-3 col-span-12"
                src={formState.img}
                alt="product"
              />

              <div className="form-group w-full lg:col-span-6 col-span-12">
                <label
                  className="mb-1 lg:mb-3 block text-sm font-medium text-black dark:text-white"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  id="name"
                  type="text"
                  className="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="name"
                  onChange={(e) => handleChange(e.target.value, "name")}
                  value={formState.name}
                />
                {errors.name && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.name}
                  </p>
                )}
              </div>

              <div className="form-group w-full col-span-6 lg:col-span-3">
                <label
                  className="mb-1 lg:mb-3 block text-sm font-medium text-black dark:text-white"
                  htmlFor="price"
                >
                  Price{" "}
                </label>
                <input
                  type="number"
                  className="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="price"
                  onChange={(e) => handleChange(e.target.value, "price")}
                  value={formState.price}
                />
                {errors.price && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.price}
                  </p>
                )}
              </div>
              <div className="form-group w-full col-span-6 lg:col-span-3">
                <label
                  className="mb-1 lg:mb-3 block text-sm font-medium text-black dark:text-white"
                  htmlFor="avail_stock"
                >
                  Available Stock
                </label>
                <input
                  type="number"
                  className="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="avail_stock"
                  onChange={(e) => handleChange(e.target.value, "avail_stock")}
                  value={formState.avail_stock}
                />
                {errors.avail_stock && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.avail_stock}
                  </p>
                )}
              </div>
              <div className="form-group w-full col-span-6 lg:col-span-3">
                <label
                  className="mb-1 lg:mb-3 block text-sm font-medium text-black dark:text-white"
                  htmlFor="discount"
                >
                  %Discount
                </label>
                <input
                  type="number"
                  className="w-full rounded border border-stroke bg-gray py-3 pl-3 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                  name="discount_percent"
                  onChange={(e) =>
                    handleChange(e.target.value, "discount_percent")
                  }
                  value={formState.discount_percent}
                />
                {errors.discount_percent && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.discount_percent}
                  </p>
                )}
              </div>

              {/* Featured */}
              <div className="py-3 w-full col-span-6 lg:col-span-3">
                <label htmlFor="featured" className="pb-2">
                  Featured
                </label>
                <Select
                  defaultInputValue={formState.featured}
                  defaultValue={formState.featured}
                  value={myOption.filter(
                    (option) => option.value === formState.feautured
                  )}
                  options={myOption}
                  id="featured"
                  name="featured"
                  placeholder="Featured? "
                  onChange={(e) => handleChange(e.value, "feautured")}
                />
                {errors.featured && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.featured}
                  </p>
                )}
              </div>

              {/* APPLY DISCOUNT */}
              <div className="py-3 w-full col-span-6 lg:col-span-3">
                <label htmlFor="apply_discount" className="pb-2">
                  Apply discount?
                </label>
                <Select
                  options={myOption}
                  value={myOption.filter(
                    (option) => option.value === formState.discount_avail
                  )}
                  id="apply_discount"
                  name="apply_discount"
                  placeholder="Apply discount?"
                  onChange={(e) => handleChange(e.value, "discount_avail")}
                />
                {errors.discount_avail && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.discount_avail}
                  </p>
                )}
              </div>

              {/* NEW ARRIVAL */}
              <div className="py-3 w-full col-span-6 lg:col-span-3">
                <label htmlFor="featured" className="pb-2">
                  New Arrival
                </label>
                <Select
                  options={myOption}
                  name="new_arrival"
                  placeholder="New arrival"
                  value={myOption.filter(
                    (option) => option.value === formState.new_arrival
                  )}
                  onChange={(e) => handleChange(e.value, "new_arrival")}
                />
                {errors.new_arrival && (
                  <p className=" capitalize text-red-400" role="alert">
                    {errors.new_arrival}
                  </p>
                )}
              </div>
            </div>

            <div className="flex justify-end items-end gap-x-5 mt-10">
              <button
                disabled={isLoading}
                className="btn  flex justify-center rounded bg-neutral-500 py-2 px-6 font-medium text-gray hover:shadow-1"
                type="button"
                onClick={() => dispatch(close())}
              >
                Cancel
              </button>{" "}
              <button
              disabled={isLoading}
                className="btn  flex justify-center rounded bg-blue-400 py-2 px-6 font-medium text-gray hover:shadow-1"
                type="button"
                onClick={handleSubmit}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
