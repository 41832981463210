import { configureStore } from "@reduxjs/toolkit";
import productsReducer from "./features/product/productsSlice";
import userReducer from "./features/users/userSlice"
import ui_utilsReducer from "./features/ui_utils/ui_utilsSlice";
import authReducer from "./features/auth/authSlice"
// export const secret = `$P#k2w!L^8gFvQ9YhEzXnAtZb7sU3mIoCcRlN6yV1`;

export const store = configureStore({
  reducer: {
    product:productsReducer,
    user:userReducer,
    ui:ui_utilsReducer,
    auth:authReducer
  },
});
