import React from "react";
import { useDispatch } from "react-redux";
// import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import { Link, useLocation } from "react-router-dom";
import { fetchAllProduct, paginate } from "../state/features/product/productsSlice";
// import queryString from "query-string";`

function Pagination({ next, prev, reducer }) {
  const dispatch = useDispatch();

  return (
    <div className=" mt-10  flex justify-center items-center w-full">
      <div className="pagination  flex justify-between w-full py-3">
      <div className="">  {prev && (
          <button
          className="cursor-pointer bg-[#282828] py-2 px-4"
          onClick={() => {
              
              dispatch(reducer(prev));
          
    
          }}
        >
            <span className="px-2 lg:px-4 py-4 text-white">
              {/* <GoArrowLeft className="inline-flex" /> */}
              Prev
            </span>
          </button>
        )}</div>

        {/* <Link to={goto(1)}>
          <span
            className={`${
              current_page === 1 ? "bg-[#282828] text-white" : ""
            } px-2 lg:px-4 py-4  border-r  border-gray-300`}
          >
            {1}
          </span>
        </Link>

        {current_page - 3 > 1 && (
          <span className={` px-2 lg:px-4 py-4 border-r  border-gray-300`}>...</span>
        )}

        {current_page - 2 <= 1 ? (
          ""
        ) : (
          <Link to={goto(current_page - 2)}>
            <span className={`${""} px-2 lg:px-4 py-4 border-r  border-gray-300`}>
              {current_page - 2}
            </span>
          </Link>
        )}
        {current_page - 1 <= 1 ? (
          ""
        ) : (
          <Link to={goto(current_page - 1)}>
            <span className={`${""} px-2 lg:px-4 py-4 border-r  border-gray-300`}>
              {current_page - 1}
            </span>
          </Link>
        )}
        {current_page !== 1 && (
          <Link to={goto(current_page)}>
            <span
              className={`bg-[#282828] text-white px-2 lg:px-4 py-4 border-r  border-gray-300`}
            >
              {current_page}
            </span>
          </Link>
        )}
        {current_page + 1 >= availablepage ? (
          ""
        ) : (
          <Link to={goto(current_page + 1)}>
            <span className={`${""} px-2 lg:px-4 py-4 border-r  border-gray-300`}>
              {current_page + 1}
            </span>
          </Link>
        )}
        {current_page + 2 >= availablepage ? (
          ""
        ) : (
          <Link to={goto(current_page + 2)}>
            <span className={`${""} px-2 lg:px-4 py-4 border-r  border-gray-300`}>
              {current_page + 2}
            </span>
          </Link>
        )}

        {current_page + 3 < availablepage && (
          <span
            className={`${
              current_page === availablepage ? "bg-[#282828] text-white" : ""
            } px-2 lg:px-4 py-4 border-r  border-gray-300`}
          >
            ...
          </span>
        )}

        {availablepage !== current_page && (
          <Link to={goto(availablepage)}>
            <span
              className={`${
                current_page === availablepage ? "bg-[#282828] text-white" : ""
              } px-2 lg:px-4 py-4 border-r  border-gray-300`}
            >
              {availablepage}
            </span>
          </Link>
        )} */}

        {next && (
          <button
          className="cursor-pointer bg-[#282828] py-2 px-4 rounded"
          onClick={() => {
                dispatch(reducer(next));
            }}
          >
            <span className="px-2 lg:px-4 py-4 text-white ">
              {/* <GoArrowRight className="inline-flex" /> */}
              Next
            </span>
          </button>
        )}
      </div>
    </div>
  );
}

export default Pagination;
